import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,
    Grid,
    CircularProgress,
    Box,
    Container,
    Alert,
    Snackbar,
} from '@mui/material';
import { CheckCircle, Bolt, InfoOutlined, Sledding, YouTube } from '@mui/icons-material';
import { tiers, Plan } from './Pricing';
import axios, { isAxiosError } from 'axios';
import AppBarToo from './AppBarToo';  // Add this import
import { loadStripe } from '@stripe/stripe-js';
import TikTokIcon from './TikTokIcon';
import { Instagram } from '@mui/icons-material';
import XIcon from './XIcon';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const SubscriptionManagement: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [currentPlan, setCurrentPlan] = useState<Plan | null>(null);
    const [availablePlans, setAvailablePlans] = useState<Plan[]>([]);
    const [youtubeAuthStatus, setYoutubeAuthStatus] = useState<'authenticated' | 'unauthenticated' | 'loading'>('loading');
    const [tikTokAuthStatus, setTikTokAuthStatus] = useState<'authenticated' | 'unauthenticated' | 'loading'>('loading');
    const [xAuthStatus, setXAuthStatus] = useState<'authenticated' | 'unauthenticated' | 'loading'>('loading');
    const [instagramAuthStatus, setInstagramAuthStatus] = useState<'authenticated' | 'unauthenticated' | 'loading'>('loading');
    const [apiError, setApiError] = useState<string | null>(null);
    const [notification, setNotification] = useState<{ message: string, severity: 'success' | 'error' | 'info' | 'warning' } | null>(null);

    const handleXAuth = async () => {
        try {
            setXAuthStatus('loading');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/x_auth`, { withCredentials: true });
            if (response.data.auth_url) {
                window.location.href = response.data.auth_url;
            }
        } catch (error) {
            console.error('Error initiating X authentication:', error);
            setNotification({ message: 'Sorry, there was an error setting up X credentials. Please try again later.', severity: 'error' });
            setXAuthStatus('unauthenticated');
        }
    };

    const handleInstagramAuth = async () => {
        try {
            setInstagramAuthStatus('loading');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/instagram_auth`, { withCredentials: true });
            if (response.data.auth_url) {
                window.location.href = response.data.auth_url;
            }
        } catch (error) {
            console.error('Error initiating Instagram authentication:', error);
            setNotification({ message: 'Sorry, there was an error setting up Instagram credentials. Please try again later.', severity: 'error' });
            setInstagramAuthStatus('unauthenticated');
        }
    };

    const handleYouTubeAuth = async () => {
        try {
            setYoutubeAuthStatus('loading');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/youtube_auth`, { withCredentials: true });
            if (response.data.auth_url) {
                window.location.href = response.data.auth_url;
            } else {
                setYoutubeAuthStatus('authenticated');
                setNotification({ message: 'YouTube credentials have been set up or refreshed successfully.', severity: 'success' });
            }
        } catch (error) {
            console.error('Error with YouTube authentication:', error);
            setNotification({ message: 'Sorry, there was an error with YouTube credentials. Please try again later.', severity: 'error' });
            setYoutubeAuthStatus('unauthenticated');
        }
    };

    const handleTikTokAuth = async () => {
        try {
            setTikTokAuthStatus('loading');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/tiktok_auth`, { withCredentials: true });
            if (response.status === 500) {
                throw new Error('Server error occurred while authenticating with TikTok');
            }
            if (response.data.auth_url) {
                window.location.href = response.data.auth_url;
            }
        } catch (error) {
            console.error('Error initiating TikTok authentication:', error);
            setNotification({ message: 'Sorry, there was an error setting up TikTok credentials. Please try again later.', severity: 'error' });
            setTikTokAuthStatus('unauthenticated');
        }
    };

    const handleRemoveYouTubeCredentials = async () => {
        try {
            setYoutubeAuthStatus('loading');
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_DOMAIN2}/api/remove_youtube_credentials`,
                {},
                { withCredentials: true }
            );
            setYoutubeAuthStatus('unauthenticated');
            setNotification({ message: 'YouTube credentials have been removed successfully.', severity: 'success' });
        } catch (error) {
            console.error('Error removing YouTube credentials:', error);
            setNotification({ message: 'Sorry, there was an error removing YouTube credentials. Please try again later.', severity: 'error' });
            // Refresh status to get current state
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/youtube_auth_status`, { withCredentials: true });
            setYoutubeAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
        }
    };

    const handleRemoveTikTokCredentials = async () => {
        try {
            setTikTokAuthStatus('loading');
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_DOMAIN2}/api/remove_tiktok_credentials`,
                {},
                { withCredentials: true }
            );
            setTikTokAuthStatus('unauthenticated');
            setNotification({ message: 'TikTok credentials have been removed successfully.', severity: 'success' });
        } catch (error) {
            console.error('Error removing TikTok credentials:', error);
            setNotification({ message: 'Sorry, there was an error removing TikTok credentials. Please try again later.', severity: 'error' });
            // Refresh status to get current state
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/tiktok_auth_status`, { withCredentials: true });
            setTikTokAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
        }
    };

    const handleRemoveXCredentials = async () => {
        try {
            setXAuthStatus('loading');
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_DOMAIN2}/api/remove_x_credentials`,
                {},
                { withCredentials: true }
            );
            setXAuthStatus('unauthenticated');
            setNotification({ message: 'X credentials have been removed successfully.', severity: 'success' });
        } catch (error) {
            console.error('Error removing X credentials:', error);
            setNotification({ message: 'Sorry, there was an error removing X credentials. Please try again later.', severity: 'error' });
            // Refresh status to get current state
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/x_auth_status`, { withCredentials: true });
            setXAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
        }
    };

    const handleRemoveInstagramCredentials = async () => {
        try {
            setInstagramAuthStatus('loading');
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_DOMAIN2}/api/remove_instagram_credentials`,
                {},
                { withCredentials: true }
            );
            setInstagramAuthStatus('unauthenticated');
            setNotification({ message: 'Instagram credentials have been removed successfully.', severity: 'success' });
        } catch (error) {
            console.error('Error removing Instagram credentials:', error);
            setNotification({ message: 'Sorry, there was an error removing Instagram credentials. Please try again later.', severity: 'error' });
            // Refresh status to get current state
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/instagram_auth_status`, { withCredentials: true });
            setInstagramAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
        }
    };

    useEffect(() => {
        const checkXAuthStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/x_auth_status`, { withCredentials: true });
                setXAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
            } catch (error) {
                console.error('Error checking X auth status:', error);
                setXAuthStatus('unauthenticated');
            }
        };

        checkXAuthStatus();
    }, []);

    useEffect(() => {
        const checkInstagramAuthStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/instagram_auth_status`, { withCredentials: true });
                setInstagramAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
            } catch (error) {
                console.error('Error checking Instagram auth status:', error);
                setInstagramAuthStatus('unauthenticated');
            }
        };

        checkInstagramAuthStatus();
    }, []);

    useEffect(() => {
        const checkYouTubeAuthStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/youtube_auth_status`, { withCredentials: true });
                setYoutubeAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
            } catch (error) {
                console.error('Error checking YouTube auth status:', error);
                setYoutubeAuthStatus('unauthenticated');
            }
        };

        checkYouTubeAuthStatus();
    }, []);

    useEffect(() => {
        const checkTikTokAuthStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/tiktok_auth_status`, { withCredentials: true });
                setTikTokAuthStatus(response.data.authenticated ? 'authenticated' : 'unauthenticated');
            } catch (error) {
                console.error('Error checking TikTok auth status:', error);
                setTikTokAuthStatus('unauthenticated');
            }
        };

        checkTikTokAuthStatus();
    }, []);

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/get_current_plan`, { withCredentials: true });
                const currentPlanTitle = response.data.plan;
                setCurrentPlan(tiers.find(plan => plan.title.toLowerCase() === currentPlanTitle) || null);
                setAvailablePlans(tiers);
            } catch (error) {
                console.error('Error fetching subscription data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionData();
    }, []);

    const handleSubscribe = async (planId: string) => {
        setLoading(true);
        setApiError(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/create-portal-session`,
                { plan: planId },
                { withCredentials: true }
            );
            if (response.data.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            if (isAxiosError(error) && error.response?.status === 422) {
                try {
                    // Create a new checkout session
                    const stripe = await stripePromise;
                    if (!stripe) {
                        throw new Error('Stripe failed to initialize');
                    }

                    const checkoutResponse = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/create-checkout-session`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ priceId: tiers.find(tier => tier.title === planId)?.priceId }),
                        credentials: 'include',
                    });

                    if (!checkoutResponse.ok) {
                        const errorText = await checkoutResponse.text();
                        throw new Error(`Payment error: ${checkoutResponse.status} - ${errorText || 'Unknown error'}`);
                    }

                    const session = await checkoutResponse.json();
                    const result = await stripe.redirectToCheckout({
                        sessionId: session.id,
                    });

                    if (result.error) {
                        throw new Error(result.error.message);
                    }
                } catch (checkoutError) {
                    console.error('Error during checkout:', checkoutError);
                    setApiError(checkoutError instanceof Error ? checkoutError.message : 'Payment processing failed');
                }
            } else {
                console.error('Error updating subscription:', error);
                setApiError('Failed to update subscription. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleCloseError = () => {
        setApiError(null);
    };

    const handleCloseNotification = () => {
        setNotification(null);
    };

    const handleCancel = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/create-portal-session`, {}, { withCredentials: true });
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error canceling subscription:', error);
        } finally {
            setLoading(false);
        }
    };

    const getButtonText = (plan: Plan) => {
        if (loading) return "Processing...";
        if (currentPlan?.title === plan.title) return "Cancel Subscription";
        if (currentPlan?.title === 'Professional' && plan.title === 'Basic') return "Downgrade";
        if ((currentPlan?.title === 'Professional' || currentPlan?.title === 'Basic') && plan.title === 'Free') return "Downgrade";
        return "Upgrade";
    };

    const getButtonIcon = (plan: Plan) => {
        if ((currentPlan?.title === 'Professional' || currentPlan?.title === 'Basic') && plan.title === 'Free') return <Sledding />;
        if (plan.title === 'Professional') return <Bolt />;
        return null;
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!currentPlan) {
        return (
            <Box sx={{ maxWidth: 1200, margin: 'auto', p: 4, textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom>
                    Sorry, we couldn't find your subscription.
                </Typography>
                <Typography variant="body1">
                    Please try again later or contact us for assistance.
                </Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg">
            <AppBarToo />
            <Box sx={{ maxWidth: "xl", margin: 'auto', p: 4 }}>
                <Typography variant="h5" gutterBottom fontWeight="bold">
                    Your Subscription
                </Typography>
                <Grid container spacing={3}>
                    {availablePlans.map((plan) => (
                        <Grid item xs={12} sm={6} md={4} key={plan.title}>
                            <Card
                                variant="outlined"
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: currentPlan?.title === plan.title ? '2px solid primary.main' : undefined
                                }}
                            >
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                        <Typography variant="h6">{plan.title}</Typography>
                                        {currentPlan?.title === plan.title && (
                                            <Box bgcolor="secondary.main" color="secondary.contrastText" px={1} py={0.5} borderRadius={1} display="flex" alignItems="center">
                                                <CheckCircle fontSize="small" sx={{ mr: 0.5 }} />
                                                <Typography variant="caption">Current Plan</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <Typography variant="h5" color="primary" gutterBottom>${plan.price}/month</Typography>
                                    <ul>
                                        {plan.description.map((feature, index) => (
                                            <li key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    {currentPlan?.title === plan.title ? (
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={handleCancel}
                                            disabled={loading || plan.title === 'Free'}
                                        >
                                            {loading ? "Processing..." : "Cancel Subscription"}
                                        </Button>
                                    ) : (
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            sx={{
                                                backgroundColor: '#1AB69D',
                                                '&:hover': {
                                                    backgroundColor: '#158F7D',
                                                },
                                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                width: '100%',
                                                color: 'white',
                                            }}
                                            onClick={() => handleSubscribe(plan.title)}
                                            disabled={loading}
                                            endIcon={getButtonIcon(plan)}
                                        >
                                            {getButtonText(plan)}
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box mt={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <InfoOutlined color="warning" sx={{ mr: 1 }} />
                                <Typography variant="h6">Important Information</Typography>
                            </Box>
                            <Typography>
                                If you upgrade your plan, the changes will take effect immediately.
                                If you downgrade your plan, the changes will take effect at your next billing date.
                                Your billing will be adjusted accordingly in both cases.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={4}>
                    <Typography sx={{ mx: 1 }} variant="h5" gutterBottom fontWeight="bold">
                        Your Credentials
                    </Typography>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <YouTube color="error" sx={{ mr: 1 }} />
                                <Typography variant="h6">YouTube Credentials</Typography>
                            </Box>
                            <Typography variant="body1" mb={2}>
                                {youtubeAuthStatus === 'authenticated'
                                    ? 'Your YouTube credentials are set up. You can refresh them if needed.'
                                    : 'Set up your YouTube credentials to enable video uploads directly from our platform.'}
                            </Typography>
                            <Box display="flex" alignItems="center" gap={2}>
                                {youtubeAuthStatus === 'authenticated' && (
                                    <Box display="flex" alignItems="center">
                                        <CheckCircle color="success" sx={{ mr: 1 }} />
                                        <Typography variant="body1">Credentials ready</Typography>
                                    </Box>
                                )}
                                <Button
                                    variant={youtubeAuthStatus === 'authenticated' ? "outlined" : "contained"}
                                    color="error"
                                    startIcon={<YouTube />}
                                    onClick={handleYouTubeAuth}
                                    disabled={youtubeAuthStatus === 'loading'}
                                >
                                    {youtubeAuthStatus === 'loading' ? 'Processing...' :
                                        youtubeAuthStatus === 'authenticated' ? 'Refresh Credentials' : 'Set Up YouTube Credentials'}
                                </Button>
                                {youtubeAuthStatus === 'authenticated' && (
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={handleRemoveYouTubeCredentials}
                                    >
                                        Remove Credentials
                                    </Button>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <TikTokIcon sx={{ mr: 1, color: "black" }} />
                                <Typography variant="h6">TikTok Credentials</Typography>
                            </Box>
                            <Typography variant="body1" mb={2}>
                                {tikTokAuthStatus === 'authenticated'
                                    ? 'Your TikTok credentials are set up. You can refresh them if needed.'
                                    : 'Set up your TikTok credentials to enable video uploads directly from our platform.'}
                            </Typography>
                            <Box display="flex" alignItems="center" gap={2}>
                                {tikTokAuthStatus === 'authenticated' && (
                                    <Box display="flex" alignItems="center">
                                        <CheckCircle color="success" sx={{ mr: 1 }} />
                                        <Typography variant="body1">Credentials ready</Typography>
                                    </Box>
                                )}
                                <Button
                                    variant={tikTokAuthStatus === 'loading' ? "outlined" : tikTokAuthStatus === 'authenticated' ? "outlined" : "contained"}
                                    startIcon={<TikTokIcon />}
                                    onClick={handleTikTokAuth}
                                    disabled={tikTokAuthStatus === 'loading'}
                                >
                                    {tikTokAuthStatus === 'loading' ? 'Processing...' :
                                        tikTokAuthStatus === 'authenticated' ? 'Refresh Credentials' : 'Set Up TikTok Credentials'}
                                </Button>
                                {tikTokAuthStatus === 'authenticated' && (
                                    <Button
                                        variant="outlined"
                                        onClick={handleRemoveTikTokCredentials}
                                    >
                                        Remove Credentials
                                    </Button>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <XIcon sx={{ mr: 1, color: "black" }} />
                                <Typography variant="h6" sx={{ ml: 1 }}>X Credentials</Typography>
                            </Box>
                            <Typography variant="body1" mb={2}>
                                {xAuthStatus === 'authenticated'
                                    ? 'Your X credentials are set up. You can refresh them if needed.'
                                    : 'Set up your X credentials to enable posting directly from our platform.'}
                            </Typography>
                            <Box display="flex" alignItems="center" gap={2}>
                                {xAuthStatus === 'authenticated' && (
                                    <Box display="flex" alignItems="center">
                                        <CheckCircle color="success" sx={{ mr: 1 }} />
                                        <Typography variant="body1">Credentials ready</Typography>
                                    </Box>
                                )}
                                <Button
                                    variant={xAuthStatus === 'authenticated' ? "outlined" : xAuthStatus === 'loading' ? "outlined" : "contained"}
                                    startIcon={<XIcon />}
                                    onClick={handleXAuth}
                                    disabled={xAuthStatus === 'loading'}
                                >
                                    {xAuthStatus === 'loading' ? 'Processing...' :
                                        xAuthStatus === 'authenticated' ? 'Refresh Credentials' : 'Set Up X Credentials'}
                                </Button>
                                {xAuthStatus === 'authenticated' && (
                                    <Button
                                        variant="outlined"
                                        onClick={handleRemoveXCredentials}
                                    >
                                        Remove Credentials
                                    </Button>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <Instagram sx={{ mr: 1, color: "#E4405F" }} />
                                <Typography variant="h6">Instagram Credentials</Typography>
                            </Box>
                            <Typography variant="body1" mb={2}>
                                {instagramAuthStatus === 'authenticated'
                                    ? 'Your Instagram credentials are set up. You can refresh them if needed.'
                                    : 'Set up your Instagram credentials to enable posting directly from our platform.'}
                            </Typography>
                            <Box display="flex" alignItems="center" gap={2}>
                                {instagramAuthStatus === 'authenticated' && (
                                    <Box display="flex" alignItems="center">
                                        <CheckCircle color="success" sx={{ mr: 1 }} />
                                        <Typography variant="body1">Credentials ready</Typography>
                                    </Box>
                                )}
                                <Button
                                    variant={instagramAuthStatus === 'authenticated' ? "outlined" : "contained"}
                                    startIcon={<Instagram />}
                                    onClick={handleInstagramAuth}
                                    disabled={instagramAuthStatus === 'loading'}
                                    sx={{
                                        background: instagramAuthStatus === 'authenticated' ? 'transparent' :
                                            'linear-gradient(45deg, #d87f1f 0%, #d15226 25%, #c91b32 50%, #b01851 75%, #9c1271 100%)',
                                    }}
                                >
                                    {instagramAuthStatus === 'loading' ? 'Processing...' :
                                        instagramAuthStatus === 'authenticated' ? 'Refresh Credentials' : 'Set Up Instagram Credentials'}
                                </Button>
                                {instagramAuthStatus === 'authenticated' && (
                                    <Button
                                        variant="outlined"
                                        onClick={handleRemoveInstagramCredentials}
                                    >
                                        Remove Credentials
                                    </Button>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>

            {/* Add notification Snackbar */}
            <Snackbar
                open={!!notification}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity={notification?.severity || 'info'}
                    sx={{ width: '100%' }}
                >
                    {notification?.message}
                </Alert>
            </Snackbar>

            {/* Keep the existing error Snackbar */}
            <Snackbar
                open={!!apiError}
                autoHideDuration={6000}
                onClose={handleCloseError}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {apiError}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default SubscriptionManagement;