import React from 'react';
import { Box, Typography, Button, TextField, FormControl, Grid, FormControlLabel, Radio, RadioGroup, Chip, InputLabel, Select, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { PostSettings } from '../types/PostSettings';

const YOUTUBE_CATEGORIES = [
    { id: '1', name: 'Film & Animation' },
    { id: '2', name: 'Autos & Vehicles' },
    { id: '10', name: 'Music' },
    { id: '15', name: 'Pets & Animals' },
    { id: '17', name: 'Sports' },
    { id: '19', name: 'Travel & Events' },
    { id: '20', name: 'Gaming' },
    { id: '22', name: 'People & Blogs' },
    { id: '23', name: 'Comedy' },
    { id: '24', name: 'Entertainment' },
    { id: '25', name: 'News & Politics' },
    { id: '26', name: 'Howto & Style' },
    { id: '27', name: 'Education' },
    { id: '28', name: 'Science & Technology' },
    { id: '29', name: 'Nonprofits & Activism' },
];

const YOUTUBE_LANGUAGES = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'it', name: 'Italian' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'ru', name: 'Russian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ko', name: 'Korean' },
    { code: 'zh', name: 'Chinese' },
];

interface YouTubeFormProps {
    postSettings: PostSettings;
    setPostSettings: React.Dispatch<React.SetStateAction<PostSettings>>;
    onClose: () => void;
    postingStatus: 'idle' | 'loading' | 'success';
    handlePost: () => void;
    renderPostButton: (platform: 'YouTube' | 'TikTok' | 'X' | 'Instagram',
        postingStatus: 'idle' | 'loading' | 'success',
        handlePost: () => void) => JSX.Element;
}

const YouTubeForm: React.FC<YouTubeFormProps> = ({
    postSettings,
    setPostSettings,
    onClose,
    postingStatus,
    handlePost,
    renderPostButton
}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
        }}>
            <TextField
                label="Title"
                fullWidth
                variant="outlined"
                value={postSettings.youtubeSettings?.title}
                onChange={(e) => setPostSettings(prev => ({
                    ...prev,
                    youtubeSettings: prev.youtubeSettings ? {
                        ...prev.youtubeSettings,
                        title: e.target.value
                    } : undefined
                }))}
                sx={{ mt: 2 }}
            />
            <TextField
                label="Description"
                fullWidth
                variant="outlined"
                value={postSettings.youtubeSettings?.description}
                onChange={(e) => setPostSettings(prev => ({
                    ...prev,
                    youtubeSettings: prev.youtubeSettings ? {
                        ...prev.youtubeSettings,
                        description: e.target.value
                    } : undefined
                }))}
                sx={{ mb: 1 }}
            />
            <Grid container spacing={4}>
                {/* Left column - Visibility Controls */}
                <Grid item xs={12} md={4}>
                    <FormControl component="fieldset" sx={{ width: '100%' }}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>Visibility</Typography>
                        <RadioGroup
                            value={postSettings.youtubeSettings?.visibility}
                            onChange={(e) => setPostSettings(prev => ({
                                ...prev,
                                youtubeSettings: prev.youtubeSettings ? {
                                    ...prev.youtubeSettings,
                                    visibility: e.target.value as 'public' | 'private' | 'unlisted' | 'scheduled',
                                    scheduleDate: null
                                } : undefined
                            }))}
                        >
                            <FormControlLabel
                                value="public"
                                control={<Radio />}
                                label="Public"
                            />
                            <FormControlLabel
                                value="private"
                                control={<Radio />}
                                label="Private"
                            />
                            <FormControlLabel
                                value="unlisted"
                                control={<Radio />}
                                label="Unlisted"
                            />
                            <FormControlLabel
                                value="scheduled"
                                control={<Radio />}
                                label="Scheduled"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {/* Right column */}
                <Grid item xs={12} md={8}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={postSettings.youtubeSettings?.categoryId}
                                label="Category"
                                onChange={(e) => setPostSettings(prev => ({
                                    ...prev,
                                    youtubeSettings: prev.youtubeSettings ? {
                                        ...prev.youtubeSettings,
                                        categoryId: e.target.value
                                    } : undefined
                                }))}
                            >
                                {YOUTUBE_CATEGORIES.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box>
                            <TextField
                                label="Add Tags"
                                fullWidth
                                variant="outlined"
                                placeholder="Type and press Enter to add tags"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        const input = e.target as HTMLInputElement;
                                        const newTag = input.value.trim();
                                        if (newTag && (!postSettings.youtubeSettings?.tags?.includes(newTag))) {
                                            setPostSettings(prev => ({
                                                ...prev,
                                                youtubeSettings: prev.youtubeSettings ? {
                                                    ...prev.youtubeSettings,
                                                    tags: [...(prev.youtubeSettings.tags || []), newTag]
                                                } : undefined
                                            }));
                                            input.value = '';
                                        }
                                    }
                                }}
                            />
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                {postSettings.youtubeSettings?.tags?.map((tag) => (
                                    <Chip
                                        key={tag}
                                        label={tag}
                                        onDelete={() => {
                                            setPostSettings(prev => ({
                                                ...prev,
                                                youtubeSettings: prev.youtubeSettings ? {
                                                    ...prev.youtubeSettings,
                                                    tags: prev.youtubeSettings.tags?.filter(t => t !== tag)
                                                } : undefined
                                            }));
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>

                        <FormControl fullWidth>
                            <InputLabel>Default Language</InputLabel>
                            <Select
                                value={postSettings.youtubeSettings?.defaultLanguage}
                                label="Default Language"
                                onChange={(e) => setPostSettings(prev => ({
                                    ...prev,
                                    youtubeSettings: prev.youtubeSettings ? {
                                        ...prev.youtubeSettings,
                                        defaultLanguage: e.target.value
                                    } : undefined
                                }))}
                            >
                                {YOUTUBE_LANGUAGES.map((language) => (
                                    <MenuItem key={language.code} value={language.code}>
                                        {language.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {postSettings.youtubeSettings?.visibility === 'scheduled' && (
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DateTimePicker sx={{ mt: 1 }}
                                label="Schedule Date"
                                value={postSettings.youtubeSettings?.scheduleDate}
                                onChange={(newValue) => setPostSettings(prev => ({
                                    ...prev,
                                    youtubeSettings: prev.youtubeSettings ? {
                                        ...prev.youtubeSettings,
                                        scheduleDate: newValue
                                    } : undefined
                                }))}
                            />
                        </LocalizationProvider>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        mt: 2,
                        alignItems: 'flex-start'
                    }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={onClose}
                            size="large"
                            sx={{ textTransform: 'none' }}
                        >
                            Back
                        </Button>
                        {renderPostButton('YouTube', postingStatus, handlePost)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default YouTubeForm; 