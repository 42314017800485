import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider, useUser } from './contexts/UserContext';
import MarketingPage from './MarketingPage';
import ViralActionPage from './ViralActionPage';
import getMPTheme from './theme/getMPTheme';
import ViralVideoPage from './ViralVideoPage';
import AboutUs from './pages/AboutUs';
import Box from '@mui/material/Box';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import SubscriptionManagement from './components/SubscriptionManagement';
import { LoginProvider } from './contexts/LoginContext';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean; errorInfo: string }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, errorInfo: '' };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, errorInfo: error?.message || 'An unknown error occurred' };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log the error to an error reporting service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            p: 4,
            textAlign: 'center',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 500,
              color: (theme) => theme.palette.error.main,
              mb: 2
            }}
          >
            Oops! Something Unexpected Happened
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              color: (theme) => theme.palette.text.secondary,
              lineHeight: 1.6
            }}
          >
            We apologize for the inconvenience. Our team has been notified and is working to resolve this issue.
            {this.state.errorInfo && (
              <Box component="span" sx={{ display: 'block', mt: 1, fontSize: '0.9em', opacity: 0.8 }}>
                Error details: {this.state.errorInfo}
              </Box>
            )}
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => window.location.reload()}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1.1rem',
              backgroundColor: (theme) => theme.palette.primary.main,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.dark,
              },
              boxShadow: 2,
            }}
          >
            Try Again
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading } = useUser();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MarketingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/viral-action" element={
        <ProtectedRoute>
          <ViralActionPage />
        </ProtectedRoute>
      } />
      <Route path="/viral-video" element={
        <ProtectedRoute>
          <ViralVideoPage />
        </ProtectedRoute>
      } />
      <Route path="/about" element={
        <ProtectedRoute>
          <AboutUs />
        </ProtectedRoute>
      } />
      <Route path="/subscription-management" element={
        <ProtectedRoute>
          <SubscriptionManagement />
        </ProtectedRoute>
      } />
    </Routes>
  );
}

function App() {
  const MPTheme = createTheme(getMPTheme("light"));
  const GOOGLE_CLIENT_ID = '394403859303-e8dqjpnjf2fhjmitb8tc9c2v4vdo2rv3.apps.googleusercontent.com';

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <UserProvider>
          <LoginProvider>
            <ThemeProvider theme={MPTheme}>
              <CssBaseline />
              <Box
                sx={(theme) => ({
                  width: '100%',
                  minHeight: '100vh', // Ensure it covers the full viewport height
                  backgroundRepeat: 'no-repeat',
                  backgroundAttachment: 'fixed', // This keeps the background fixed while scrolling
                  backgroundSize: 'cover',
                  backgroundImage:
                    'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(170, 75%, 85%), transparent)',
                  ...theme.applyStyles('dark', {
                    backgroundImage:
                      'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(170, 75%, 85%), transparent)',
                  }),
                })}
              >
                <Router>
                  <AppRoutes />
                </Router>
              </Box>
            </ThemeProvider>
          </LoginProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;