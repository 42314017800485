import React from 'react';
import { Box, Typography, Button, TextField, FormControl, Grid, FormControlLabel, Checkbox, RadioGroup, Radio } from '@mui/material';
import { PostSettings } from '../types/PostSettings';

interface InstagramFormProps {
    postSettings: PostSettings;
    setPostSettings: React.Dispatch<React.SetStateAction<PostSettings>>;
    onClose: () => void;
    postingStatus: 'idle' | 'loading' | 'success';
    handlePost: () => void;
    renderPostButton: (platform: 'YouTube' | 'TikTok' | 'X' | 'Instagram',
        postingStatus: 'idle' | 'loading' | 'success',
        handlePost: () => void) => JSX.Element;
}

const InstagramForm: React.FC<InstagramFormProps> = ({
    postSettings,
    setPostSettings,
    onClose,
    postingStatus,
    handlePost,
    renderPostButton
}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
        }}>
            <TextField
                label="Caption"
                fullWidth
                multiline
                variant="outlined"
                maxRows={4}
                value={postSettings.instagramSettings?.caption}
                onChange={(e) => setPostSettings(prev => ({
                    ...prev,
                    instagramSettings: prev.instagramSettings ? {
                        ...prev.instagramSettings,
                        caption: e.target.value
                    } : undefined
                }))}
                inputProps={{
                    maxLength: 2200
                }}
                helperText={`${postSettings.instagramSettings?.caption?.length || 0}/2200`}
                sx={{
                    mt: 2,
                    '& .MuiInputBase-root': {
                        height: 'auto',
                        minHeight: '50px'
                    },
                    '& .MuiInputBase-input': {
                        overflow: 'auto',
                    }
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset" sx={{ width: '100%' }}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>Visibility</Typography>
                        <RadioGroup
                            value={postSettings.instagramSettings?.visiblity}
                            onChange={(e) => setPostSettings(prev => ({
                                ...prev,
                                instagramSettings: prev.instagramSettings ? {
                                    ...prev.instagramSettings,
                                    visiblity: e.target.value as 'public' | 'private'
                                } : undefined
                            }))}
                        >
                            <FormControlLabel
                                value="public"
                                control={<Radio />}
                                label="Public"
                            />
                            <FormControlLabel
                                value="private"
                                control={<Radio />}
                                label="Private"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={postSettings.instagramSettings?.allowResharing}
                                    onChange={(e) => setPostSettings(prev => ({
                                        ...prev,
                                        instagramSettings: prev.instagramSettings ? {
                                            ...prev.instagramSettings,
                                            allowResharing: e.target.checked
                                        } : undefined
                                    }))}
                                />
                            }
                            label="Allow resharing to stories"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={postSettings.instagramSettings?.hideCommentCount}
                                    onChange={(e) => setPostSettings(prev => ({
                                        ...prev,
                                        instagramSettings: prev.instagramSettings ? {
                                            ...prev.instagramSettings,
                                            hideCommentCount: e.target.checked
                                        } : undefined
                                    }))}
                                />
                            }
                            label="Hide comment count"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={postSettings.instagramSettings?.hidelikeCount}
                                    onChange={(e) => setPostSettings(prev => ({
                                        ...prev,
                                        instagramSettings: prev.instagramSettings ? {
                                            ...prev.instagramSettings,
                                            hidelikeCount: e.target.checked
                                        } : undefined
                                    }))}
                                />
                            }
                            label="Hide like count"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        mt: 2,
                        alignItems: 'flex-start'
                    }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={onClose}
                            size="large"
                            sx={{ textTransform: 'none' }}
                        >
                            Back
                        </Button>
                        {renderPostButton('Instagram', postingStatus, handlePost)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default InstagramForm; 