import React from 'react';
import { Box, Typography, Button, Avatar } from '@mui/material';
import axios from 'axios';
import { VideoData } from './VideoData';
import ConnectIcon from '@mui/icons-material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import YouTube from '@mui/icons-material/YouTube';
import TikTokIcon from './TikTokIcon';
import XIcon from './XIcon';
import Instagram from '@mui/icons-material/Instagram';
import YouTubeForm from './platform-forms/YouTubeForm';
import TikTokForm from './platform-forms/TikTokForm';
import XForm from './platform-forms/XForm';
import InstagramForm from './platform-forms/InstagramForm';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface PostingConfigurationProps {
    platform: 'youtube' | 'tiktok' | 'x' | 'instagram' | null;
    videoData: VideoData;
    onClose: () => void;
}
interface PostSettings {
    youtubeSettings?: YoutubeSettings;
    tiktokSettings?: TikTokSettings;
    xSettings?: XSettings;
    instagramSettings?: InstagramSettings;
}
interface YoutubeSettings {
    title: string;
    description: string;
    visibility: 'public' | 'private' | 'unlisted' | 'scheduled';
    scheduleDate: Date | null;
    tags?: string[];
    categoryId?: string;
    defaultLanguage?: string;
}

interface TikTokSettings {
    caption: string;
    visiblity: 'public' | 'private' | 'friends' | null;
    disableDuet: boolean;
    disableComments: boolean;
    disableStitch: boolean;
    commentControl: 0 | 1 | 2;
    brandedContent: {
        isOrganic: boolean;
        isThirdParty: boolean;
    };
}

interface XSettings {
    caption: string;
    visiblity: 'public' | 'private';
    allowQuotes: boolean;
    allowReplies: boolean;
    conversationControl: 'everyone' | 'followersOnly' | 'mentionedOnly';
}

interface InstagramSettings {
    caption: string;
    visiblity: 'public' | 'private';
    allowResharing: boolean;
    hideCommentCount: boolean;
    hidelikeCount: boolean;
}

export function PostingConfiguration({ platform, videoData, onClose }: PostingConfigurationProps) {
    const [accountStatus, setAccountStatus] = React.useState<'checking' | 'connected' | 'not_connected'>('checking');
    const [accountDetails, setAccountDetails] = React.useState<any>(null);
    const [postSettings, setPostSettings] = React.useState<PostSettings>({
        youtubeSettings: platform === 'youtube' ? {
            title: videoData.title,
            description: videoData.description,
            visibility: 'private',
            scheduleDate: null,
            tags: [],
            categoryId: '',
            defaultLanguage: 'en'
        } : undefined,
        tiktokSettings: platform === 'tiktok' ? {
            caption: videoData.title.concat(": ").concat(videoData.description),
            visiblity: null,
            disableDuet: true,
            disableComments: true,
            disableStitch: true,
            commentControl: 0,
            brandedContent: {
                isOrganic: false,
                isThirdParty: false
            }
        } : undefined,
        xSettings: platform === 'x' ? {
            caption: videoData.title.concat(": ").concat(videoData.description),
            visiblity: "private",
            allowQuotes: true,
            allowReplies: true,
            conversationControl: 'everyone'
        } : undefined,
        instagramSettings: platform === 'instagram' ? {
            caption: videoData.title.concat(": ").concat(videoData.description),
            visiblity: "private",
            allowResharing: true,
            hideCommentCount: false,
            hidelikeCount: false,
        } : undefined
    });
    const [postingToYouTube, setPostingToYouTube] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [postingToTikTok, setPostingToTikTok] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [postingToX, setPostingToX] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [postingToInstagram, setPostingToInstagram] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [snackbar, setSnackbar] = React.useState({
        open: false,
        message: '',
        severity: 'info' as 'info' | 'warning' | 'error' | 'success',
        link: '',
        linkText: ''
    });
    const [publishedLinks, setPublishedLinks] = React.useState<{
        youtube?: string;
        tiktok?: string;
        x?: string;
        instagram?: string;
    }>({});
    const [uploadStatus, setUploadStatus] = React.useState<{
        youtube?: string;
        tiktok?: string;
        x?: string;
        instagram?: string;
    }>({});
    const checkAccountStatus = React.useCallback(async () => {
        if (!platform) return;

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_DOMAIN2}/api/${platform}_auth_status`,
                { withCredentials: true }
            );
            setAccountStatus(response.data.authenticated ? 'connected' : 'not_connected');
            if (response.data.authenticated) {
                setAccountDetails({
                    username: response.data.name,
                    profilePicture: response.data.profilePicture,
                    handle: response.data.username
                });
            }
        } catch (error) {
            console.error(`Error checking ${platform} auth status:`, error);
            setAccountStatus('not_connected');
        }
    }, [platform]);

    React.useEffect(() => {
        checkAccountStatus();
    }, [checkAccountStatus]);


    const handleConnect = async () => {
        if (!platform) return;

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_DOMAIN2}/api/${platform}_auth`,
                { withCredentials: true }
            );
            window.location.href = response.data.auth_url;
        } catch (error) {
            console.error(`Error initiating ${platform} auth:`, error);
        }
    };
    const handlePostToYouTube = async () => {
        setPostingToYouTube('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/post_to_youtube`, {
                video: vid_path,
                postSettings
            }, { withCredentials: true });

            if (response.status === 200) {
                setPostingToYouTube('success');

                // Use the post_url from the response if available
                if (response.data.post_url) {
                    setSnackbar({
                        open: true,
                        message: 'Upload complete! Check out your post on',
                        severity: 'success',
                        link: response.data.post_url,
                        linkText: 'YouTube'
                    });
                } else if (response.data.video_id) {
                    // Fallback to constructing URL from video_id
                    const youtubeLink = `https://www.youtube.com/watch?v=${response.data.video_id}`;
                    setSnackbar({
                        open: true,
                        message: 'Upload complete! Check out your post on',
                        severity: 'success',
                        link: youtubeLink,
                        linkText: 'YouTube'
                    });
                }
            }
        } catch (error: any) {
            console.error('Error posting to YouTube:', error);
            setPostingToYouTube('idle');
            setSnackbar({
                open: true,
                message: `Upload failed. Please try again or contact us.`,
                severity: 'error',
                link: '',
                linkText: ''
            });
        }
    }
    const handlePostToTikTok = async () => {
        setPostingToTikTok('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/post_to_tiktok`, {
                video: vid_path,
                postSettings
            }, { withCredentials: true });

            if (response.status === 200) {
                setPostingToTikTok('success');

                // For TikTok, use a fixed link to the viryl_io account
                const tiktokLink = 'https://www.tiktok.com/@viryl_io';
                setSnackbar({
                    open: true,
                    message: 'Upload complete! Check out your post on',
                    severity: 'success',
                    link: tiktokLink,
                    linkText: 'TikTok'
                });
            }
        } catch (error: any) {
            console.error('Error posting to TikTok:', error);
            setPostingToTikTok('idle');
            setSnackbar({
                open: true,
                message: `Upload failed. Please try again or contact us.`,
                severity: 'error',
                link: '',
                linkText: ''
            });
        }
    };
    const handlePostToX = async () => {
        setPostingToX('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/post_to_x`, {
                video: vid_path,
                postSettings
            }, { withCredentials: true });

            if (response.status === 200) {
                setPostingToX('success');

                // Use the post_url from the response if available
                if (response.data.post_url) {
                    setSnackbar({
                        open: true,
                        message: 'Upload complete! Check out your post on',
                        severity: 'success',
                        link: response.data.post_url,
                        linkText: 'X'
                    });
                } else if (response.data.tweet_id) {
                    // Fallback to constructing URL from tweet_id
                    let xLink;
                    if (accountDetails?.handle) {
                        xLink = `https://twitter.com/${accountDetails.handle}/status/${response.data.tweet_id}`;
                    } else {
                        xLink = `https://twitter.com/i/status/${response.data.tweet_id}`;
                    }

                    setSnackbar({
                        open: true,
                        message: 'Upload complete! Check out your post on',
                        severity: 'success',
                        link: xLink,
                        linkText: 'X'
                    });
                }
            }
        } catch (error: any) {
            console.error('Error posting to X:', error);
            setPostingToX('idle');
            setSnackbar({
                open: true,
                message: `Upload failed. Please try again or contact us.`,
                severity: 'error',
                link: '',
                linkText: ''
            });
        }
    };
    const handlePostToInstagram = async () => {
        setPostingToInstagram('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/post_to_instagram`, {
                video: vid_path,
                postSettings
            }, { withCredentials: true });

            if (response.status === 200) {
                setPostingToInstagram('success');

                // Use the post_url from the response if available
                if (response.data.post_url) {
                    setSnackbar({
                        open: true,
                        message: 'Upload complete! Check out your post on',
                        severity: 'success',
                        link: response.data.post_url,
                        linkText: 'Instagram'
                    });
                } else if (accountDetails?.handle) {
                    // Fallback to profile URL
                    const instagramLink = `https://www.instagram.com/${accountDetails.handle}`;
                    setSnackbar({
                        open: true,
                        message: 'Upload complete! Check out your post on',
                        severity: 'success',
                        link: instagramLink,
                        linkText: 'Instagram'
                    });
                } else {
                    setSnackbar({
                        open: true,
                        message: 'Video published to Instagram successfully!',
                        severity: 'success',
                        link: '',
                        linkText: ''
                    });
                }
            }
        } catch (error: any) {
            console.error('Error posting to Instagram:', error);
            setPostingToInstagram('idle');
            setSnackbar({
                open: true,
                message: `Upload failed. Please try again or contact us.`,
                severity: 'error',
                link: '',
                linkText: ''
            });
        }
    };
    const handleSnackbarClose = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const renderPostButton = (
        platform: 'YouTube' | 'TikTok' | 'X' | 'Instagram',
        postingStatus: 'idle' | 'loading' | 'success',
        handlePost: () => void
    ) => (
        <Box sx={{ width: '100%' }}>
            <Button
                variant={postingStatus === 'idle' ? 'contained' : 'outlined'}
                color={postingStatus === 'success' ? 'success' : 'secondary'}
                fullWidth
                disabled={postingStatus !== 'idle'}
                onClick={handlePost}
                size="medium"
                sx={{
                    textTransform: 'none',
                    position: 'relative',
                    mb: 1
                }}
                endIcon={postingStatus === 'success' ? <CheckCircleIcon sx={{ color: '#4CAF50' }} /> : null}
            >
                {postingStatus === 'success'
                    ? 'Posted Successfully'
                    : postingStatus === 'loading'
                        ? <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            Posting...
                            <CircularProgress size={20} sx={{ ml: 1 }} />
                        </Box>
                        : `Post to ${platform}`}
            </Button>
        </Box>
    );

    const navigateToSubscriptionManagement = () => {
        window.location.href = '/subscription-management';
    };

    const getPlatformIcon = () => {
        switch (platform) {
            case 'youtube':
                return <YouTube color="error" sx={{ mr: 1 }} />;
            case 'tiktok':
                return <TikTokIcon sx={{ mr: 1, color: "black" }} />;
            case 'x':
                return <XIcon sx={{ mr: 1, color: "black" }} />;
            case 'instagram':
                return <Instagram sx={{ mr: 1, color: "#E4405F" }} />;
            default:
                return <ConnectIcon sx={{ mr: 1 }} />;
        }
    };

    const getPlatformName = () => {
        return platform ? platform.charAt(0).toUpperCase() + platform.slice(1).toLowerCase() : '';
    };

    return (
        <Box>
            {accountStatus === 'not_connected' ? (
                <Box sx={{ py: 4 }}>
                    <Box display="flex" alignItems="center" mb={2}>
                        {getPlatformIcon()}
                        <Typography variant="h6">{getPlatformName()} Credentials</Typography>
                    </Box>
                    <Typography variant="body1" mb={2}>
                        Set up your {getPlatformName()} credentials to enable posting directly from our platform.
                    </Typography>
                    <Box display="flex" alignItems="center" gap={2}>
                        <Button
                            variant="outlined"
                            startIcon={getPlatformIcon()}
                            onClick={handleConnect}
                        >
                            Connect {getPlatformName()} Account
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={navigateToSubscriptionManagement}
                        >
                            Manage All Credentials
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}>
                    {accountDetails && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 2,
                            bgcolor: 'action.hover',
                            borderRadius: 1,
                            border: '1px solid rgba(0, 0, 0, 0.1)',
                        }}>
                            <Typography variant="subtitle1" fontWeight="medium">
                                Posting as:
                            </Typography>

                            <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    src={accountDetails.profilePicture}
                                    sx={{ mr: 2 }}
                                />
                                <Box>
                                    <Typography variant="subtitle1" fontWeight="medium">
                                        {accountDetails.username}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {accountDetails.handle}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}

            {/* Platform-specific forms */}
            {platform === 'youtube' && accountStatus === 'connected' && (
                <YouTubeForm
                    postSettings={postSettings}
                    setPostSettings={setPostSettings}
                    onClose={onClose}
                    postingStatus={postingToYouTube}
                    handlePost={handlePostToYouTube}
                    renderPostButton={renderPostButton}
                />
            )}

            {platform === 'tiktok' && accountStatus === 'connected' && (
                <TikTokForm
                    postSettings={postSettings}
                    setPostSettings={setPostSettings}
                    onClose={onClose}
                    postingStatus={postingToTikTok}
                    handlePost={handlePostToTikTok}
                    renderPostButton={renderPostButton}
                    setSnackbar={setSnackbar}
                />
            )}

            {platform === 'x' && accountStatus === 'connected' && (
                <XForm
                    postSettings={postSettings}
                    setPostSettings={setPostSettings}
                    onClose={onClose}
                    postingStatus={postingToX}
                    handlePost={handlePostToX}
                    renderPostButton={renderPostButton}
                />
            )}

            {platform === 'instagram' && accountStatus === 'connected' && (
                <InstagramForm
                    postSettings={postSettings}
                    setPostSettings={setPostSettings}
                    onClose={onClose}
                    postingStatus={postingToInstagram}
                    handlePost={handlePostToInstagram}
                    renderPostButton={renderPostButton}
                />
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {snackbar.message}
                        {snackbar.link && (
                            <Button
                                color="inherit"
                                size="small"
                                href={snackbar.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ ml: 1, textDecoration: 'underline', display: 'inline-flex', alignItems: 'center' }}
                                endIcon={<OpenInNewIcon fontSize="small" />}
                            >
                                {snackbar.linkText}
                            </Button>
                        )}
                    </Box>
                </Alert>
            </Snackbar>
        </Box>
    );
}




