import React from 'react';
import { Box, Typography, Button, TextField, FormControl, Grid, FormControlLabel, Checkbox, Tooltip, Switch, InputLabel, Select, MenuItem } from '@mui/material';
import { PostSettings } from '../types/PostSettings';

interface TikTokFormProps {
    postSettings: PostSettings;
    setPostSettings: React.Dispatch<React.SetStateAction<PostSettings>>;
    onClose: () => void;
    postingStatus: 'idle' | 'loading' | 'success';
    handlePost: () => void;
    renderPostButton: (platform: 'YouTube' | 'TikTok' | 'X' | 'Instagram',
        postingStatus: 'idle' | 'loading' | 'success',
        handlePost: () => void) => JSX.Element;
    setSnackbar: React.Dispatch<React.SetStateAction<{
        open: boolean;
        message: string;
        severity: 'info' | 'warning' | 'error' | 'success';
        link: string;
        linkText: string;
    }>>;
}

const TikTokForm: React.FC<TikTokFormProps> = ({
    postSettings,
    setPostSettings,
    onClose,
    postingStatus,
    handlePost,
    renderPostButton,
    setSnackbar
}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
        }}>
            <TextField
                label="Caption"
                fullWidth
                multiline
                variant="outlined"
                maxRows={4}
                value={postSettings.tiktokSettings?.caption}
                onChange={(e) => setPostSettings(prev => ({
                    ...prev,
                    tiktokSettings: prev.tiktokSettings ? {
                        ...prev.tiktokSettings,
                        caption: e.target.value
                    } : undefined
                }))}
                inputProps={{
                    maxLength: 2200
                }}
                helperText={`${postSettings.tiktokSettings?.caption?.length || 0}/2200`}
                sx={{
                    mt: 2,
                    '& .MuiInputBase-root': {
                        height: 'auto',
                        minHeight: '50px'
                    },
                    '& .MuiInputBase-input': {
                        overflow: 'auto',
                    }
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Who can view this video</InputLabel>
                        <Select
                            value={postSettings.tiktokSettings?.visiblity}
                            label="Visibility"
                            onChange={(e) => setPostSettings((prev: PostSettings) => ({
                                ...prev,
                                tiktokSettings: prev.tiktokSettings ? {
                                    ...prev.tiktokSettings,
                                    visiblity: e.target.value as 'public' | 'private' | 'friends',
                                } : undefined
                            }))}
                        >
                            <MenuItem value="public">Public</MenuItem>
                            <MenuItem
                                value="private"
                                disabled={postSettings.tiktokSettings?.brandedContent.isThirdParty}
                            >
                                <Tooltip title={
                                    postSettings.tiktokSettings?.brandedContent.isThirdParty
                                        ? "Branded content visibility cannot be set to private"
                                        : ""
                                }>
                                    <span>Private</span>
                                </Tooltip>
                            </MenuItem>
                            <MenuItem value="friends">Friends Only</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <FormControl component="fieldset" sx={{ width: '100%' }}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>Allow Users to</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!postSettings.tiktokSettings?.disableComments}
                                            onChange={(e) => setPostSettings((prev: PostSettings) => ({
                                                ...prev,
                                                tiktokSettings: prev.tiktokSettings ? {
                                                    ...prev.tiktokSettings,
                                                    disableComments: !e.target.checked
                                                } : undefined
                                            }))}
                                        />
                                    }
                                    label="Comment"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!postSettings.tiktokSettings?.disableDuet}
                                            onChange={(e) => setPostSettings((prev: PostSettings) => ({
                                                ...prev,
                                                tiktokSettings: prev.tiktokSettings ? {
                                                    ...prev.tiktokSettings,
                                                    disableDuet: !e.target.checked
                                                } : undefined
                                            }))}
                                        />
                                    }
                                    label="Duet"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!postSettings.tiktokSettings?.disableStitch}
                                            onChange={(e) => setPostSettings((prev: PostSettings) => ({
                                                ...prev,
                                                tiktokSettings: prev.tiktokSettings ? {
                                                    ...prev.tiktokSettings,
                                                    disableStitch: !e.target.checked
                                                } : undefined
                                            }))}
                                        />
                                    }
                                    label="Stitch"
                                />
                            </Box>
                        </FormControl>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={postSettings.tiktokSettings?.brandedContent.isOrganic ||
                                            postSettings.tiktokSettings?.brandedContent.isThirdParty}
                                        onChange={(e) => {
                                            if (!e.target.checked) {
                                                setPostSettings(prev => ({
                                                    ...prev,
                                                    tiktokSettings: prev.tiktokSettings ? {
                                                        ...prev.tiktokSettings,
                                                        brandedContent: {
                                                            isOrganic: false,
                                                            isThirdParty: false
                                                        }
                                                    } : undefined
                                                }));
                                            } else {
                                                setPostSettings(prev => ({
                                                    ...prev,
                                                    tiktokSettings: prev.tiktokSettings ? {
                                                        ...prev.tiktokSettings,
                                                        brandedContent: {
                                                            isOrganic: true,
                                                            isThirdParty: false
                                                        }
                                                    } : undefined
                                                }));
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <Tooltip title="Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a third party, or both.">
                                        <Typography variant="subtitle1">
                                            Disclose video content
                                        </Typography>
                                    </Tooltip>
                                }
                            />
                            {!postSettings.tiktokSettings?.brandedContent.isOrganic &&
                                !postSettings.tiktokSettings?.brandedContent.isThirdParty && (
                                    <Typography variant="body2" color="grey" sx={{ mb: 2 }}>
                                        Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a third party, or both.
                                    </Typography>
                                )}

                            {(postSettings.tiktokSettings?.brandedContent.isOrganic ||
                                postSettings.tiktokSettings?.brandedContent.isThirdParty) && (
                                    <Box sx={{ pl: 2 }}>
                                        <Box sx={{
                                            bgcolor: 'action.hover',
                                            borderRadius: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1,
                                        }}>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ mx: 1, bgcolor: 'info.lighter', p: 1.5, borderRadius: 1 }}
                                            >
                                                {postSettings.tiktokSettings?.brandedContent.isThirdParty
                                                    ? "Your video will be labeled 'Paid partnership'"
                                                    : "Your video will be labeled 'Promotional content'"
                                                }
                                                {" "}
                                                This cannot be changed once your video is posted.
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={postSettings.tiktokSettings?.brandedContent.isOrganic}
                                                        onChange={(e) => {
                                                            setPostSettings(prev => ({
                                                                ...prev,
                                                                tiktokSettings: prev.tiktokSettings ? {
                                                                    ...prev.tiktokSettings,
                                                                    brandedContent: {
                                                                        ...prev.tiktokSettings.brandedContent,
                                                                        isOrganic: e.target.checked
                                                                    }
                                                                } : undefined
                                                            }));
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Box>
                                                        <Typography variant="subtitle2">Your brand</Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            You are promoting yourself or your own business.
                                                        </Typography>
                                                    </Box>
                                                }
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={postSettings.tiktokSettings?.brandedContent.isThirdParty}
                                                        onChange={(e) => {
                                                            if (e.target.checked && postSettings.tiktokSettings?.visiblity === 'private') {
                                                                setPostSettings(prev => ({
                                                                    ...prev,
                                                                    tiktokSettings: prev.tiktokSettings ? {
                                                                        ...prev.tiktokSettings,
                                                                        visiblity: 'public',
                                                                        brandedContent: {
                                                                            ...prev.tiktokSettings.brandedContent,
                                                                            isThirdParty: true
                                                                        }
                                                                    } : undefined
                                                                }));
                                                                setSnackbar({
                                                                    open: true,
                                                                    message: 'Visibility has been changed to public as branded content cannot be private',
                                                                    severity: 'info',
                                                                    link: '',
                                                                    linkText: ''
                                                                });
                                                            } else {
                                                                setPostSettings(prev => ({
                                                                    ...prev,
                                                                    tiktokSettings: prev.tiktokSettings ? {
                                                                        ...prev.tiktokSettings,
                                                                        brandedContent: {
                                                                            ...prev.tiktokSettings.brandedContent,
                                                                            isThirdParty: e.target.checked
                                                                        }
                                                                    } : undefined
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Box>
                                                        <Typography variant="subtitle2">Branded content</Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            You are promoting another brand or a third party.
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </Box>
                                    </Box>
                                )}
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        mt: 4,
                        alignItems: 'flex-start'
                    }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={onClose}
                            size="large"
                            sx={{ textTransform: 'none' }}
                        >
                            Back
                        </Button>
                        {renderPostButton('TikTok', postingStatus, handlePost)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TikTokForm; 