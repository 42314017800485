import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TikTokIcon from './TikTokIcon';
import XIcon from './XIcon';
import InstagramIcon from '@mui/icons-material/Instagram';
import React from 'react';
import { VideoData } from './VideoData';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { PostingConfiguration } from './PostingConfiguration';
interface VideoBoxProps {
    videoData: VideoData;
    onLoad?: () => void;
}

interface PostingSuiteState {
    isOpen: boolean;
    platform: 'youtube' | 'tiktok' | 'x' | 'instagram' | null;
}

export function VideoBox({ videoData, onLoad }: VideoBoxProps) {
    const [videoKey, setVideoKey] = React.useState(0);
    const [addingSubtitles, setAddingSubtitles] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [postingSuite, setPostingSuite] = React.useState<PostingSuiteState>({
        isOpen: false,
        platform: null
    });

    const handleDownload = async () => {
        const video = videoData.videoUrl
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/download_video`, {
                params: { video: vid_path },
                responseType: 'blob', // Important for handling binary data
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${videoData.title}.mp4`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading video:', error);
        }
    };

    const handleAddSubs = async () => {
        setAddingSubtitles('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/add_subs`, {
                params: { video: vid_path },
            });

            setAddingSubtitles('success');

            // Update the video URL with the new path received from the server
            videoData.videoUrl = response.data.updated_video_url;

            setVideoKey(prevKey => prevKey + 1);
        } catch (error) {
            console.error('Error adding subtitles:', error);
            setAddingSubtitles('idle');
        }
    };

    // Add error handling for video loading
    const handleVideoLoad = (event: React.SyntheticEvent<HTMLVideoElement>) => {
        onLoad?.();
    };

    const handleVideoError = (event: React.SyntheticEvent<HTMLVideoElement>) => {
        console.error('Error loading video:', event);
        onLoad?.();
    };

    // Add ref to track component mounted state
    const isMounted = React.useRef(true);

    // Add cleanup on unmount
    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleOpenPostingSuite = (platform: PostingSuiteState['platform']) => {
        setPostingSuite({
            isOpen: true,
            platform
        });
    };

    const handleClosePostingSuite = () => {
        setPostingSuite({
            isOpen: false,
            platform: null
        });
    };

    return (
        <>
            <Grid item xs={12} sm={6} md={3}>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        overflow: 'hidden',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                        },
                    }}
                >
                    <Box sx={{
                        position: 'relative',
                        paddingTop: '177.78%',
                        bgcolor: 'grey.100',
                    }}>
                        <video
                            key={videoKey}
                            controls
                            onLoadedData={handleVideoLoad}
                            onError={handleVideoError}
                            onAbort={(e) => {
                                if (!isMounted.current) return;
                                console.warn('Video loading aborted:', e);
                                onLoad?.();
                            }}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                            preload="metadata"
                        >
                            <source src={videoData.videoUrl} type="video/mp4" />
                        </video>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1.1rem',
                                fontWeight: 600,
                                mb: 1,
                                lineHeight: 1.2,
                                height: '2.4em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {videoData.title}
                        </Typography>

                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                mb: 2,
                                height: '3em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {videoData.description}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 0.5,
                            borderTop: 1,
                            borderColor: 'divider',
                            pt: 2,
                        }}>
                            <Tooltip
                                title={addingSubtitles === 'loading' ? "This may take a while..." : (addingSubtitles === 'success' || videoData.videoUrl.includes('subtitled')) ? "" : "Add Subtitles"}
                                arrow
                            >
                                <span>
                                    <IconButton
                                        onClick={handleAddSubs}
                                        color="primary"
                                        sx={{ width: 36, height: 36 }}
                                        disabled={addingSubtitles !== 'idle' || videoData.videoUrl.includes('subtitled')}
                                    >
                                        {(addingSubtitles === 'idle' && !videoData.videoUrl.includes('subtitled')) && <AddIcon />}
                                        {addingSubtitles === 'loading' && <CircularProgress size={18} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-9px', marginLeft: '-9px' }} />}
                                        {(addingSubtitles === 'success' || videoData.videoUrl.includes('subtitled')) && <CheckIcon sx={{ color: 'green' }} />}
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Download Video" arrow>
                                <IconButton onClick={handleDownload} aria-label="download video" sx={{ width: 36, height: 36 }}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Post to YouTube" arrow>
                                <IconButton
                                    onClick={() => handleOpenPostingSuite('youtube')}
                                    sx={{ width: 36, height: 36, color: "red" }}
                                >
                                    <YouTubeIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Post to TikTok" arrow>
                                <IconButton
                                    onClick={() => handleOpenPostingSuite('tiktok')}
                                    sx={{ width: 36, height: 36 }}
                                >
                                    <TikTokIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Post to X" arrow>
                                <IconButton
                                    onClick={() => handleOpenPostingSuite('x')}
                                    sx={{ width: 36, height: 36 }}
                                >
                                    <XIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Post to Instagram" arrow>
                                <IconButton
                                    onClick={() => handleOpenPostingSuite('instagram')}
                                    sx={{ width: 36, height: 36, color: "#E4405F" }}
                                >
                                    <InstagramIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Grid>

            <Dialog
                open={postingSuite.isOpen}
                onClose={handleClosePostingSuite}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        bgcolor: 'background.default',
                        maxHeight: '90vh',
                        margin: { xs: 1, sm: 2, md: 3 },
                    }
                }}
            >
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 3,
                        borderRadius: 2,
                        p: { xs: 2, md: 3 },
                        minHeight: { xs: 'auto', md: '50vh' },
                        bgcolor: '#e0f2f1',
                        alignItems: 'center'

                    }}
                >
                    {/* Left side - Video preview using VideoBox styling */}
                    <Box sx={{
                        width: { xs: '100%', md: '33%' },
                        height: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                borderRadius: 2,
                                overflow: 'hidden',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',

                            }}
                        >
                            <Box sx={{
                                position: 'relative',
                                paddingTop: '177.78%',
                                bgcolor: 'grey.100',
                            }}>
                                <video
                                    controls
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                    }}
                                >
                                    <source src={videoData.videoUrl} type="video/mp4" />
                                </video>
                            </Box>
                        </Box>
                    </Box>

                    {/* Right side - Posting configuration */}
                    <Box sx={{
                        flex: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        p: { xs: 2, md: 3 },
                        boxShadow: '0 20px 20px rgba(0,0,0,0.1)',
                        height: '100%',
                    }}>
                        <PostingConfiguration
                            platform={postingSuite.platform}
                            videoData={videoData}
                            onClose={handleClosePostingSuite}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}